import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { get } from "lodash"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { textExceprt, getFirstTextBlock } from "../utils/helpers"
import Helmet from "react-helmet"
import SocialShareLinks from "../components/SocialShareLinks"

/* TODO: Need UX to get back to blog index */

const BlogPostTemplate = ({ data, pageContext }) => {
  const { previous, next } = pageContext
  const postData = get(data, "allPrismicPosts.nodes[0].data")
  // const tags = get(data, "allPrismicPosts.nodes[0].tags")
  const main_image = getImage(postData.main_image.gatsbyImageData)
  // console.log(data)

  const first_text = getFirstTextBlock(postData.body)
  const description = first_text ? `${textExceprt(first_text, 30)}…` : null

  const dateObj = new Date(`${postData.date} 03:00:00`)
  const published_time = dateObj.toISOString()

  return (
    <Layout>
      <Helmet>
        <body className="blog-post" />
        <title>{postData.title.text}</title>
        <meta property="article:published_time" content={published_time} />
        <meta
          name="publish_date"
          property="og:publish_date"
          content={published_time}
        />
      </Helmet>
      <Seo
        type="article"
        title={postData.title.text}
        description={description}
        author={postData.author.text}
        image={main_image ? main_image?.images.fallback.src : null}
      />
      <article>
        <section className="breadcrumb">
          <Link to="/blog">← Back to Posts</Link>
        </section>
        {main_image && (
          <div className="main-image-wrapper">
            <GatsbyImage
              className="main-image"
              layout="constrained"
              image={getImage(main_image)}
              alt={
                postData.main_image.alt
                  ? postData.main_image.alt
                  : postData.title.text
              }
              title={
                postData.main_image.alt
                  ? postData.main_image.alt
                  : postData.title.text
              }
            />
          </div>
        )}
        <section>
          <h1>{postData.title.text}</h1>
          {postData.subtitle && <h3>{postData.subtitle.text}</h3>}
          <div className="post-details">
            <div className="date">{postData.date}</div>
          </div>

          <section className="pagination top">
            <span className="link-wrapper">
              {previous && (
                <Link
                  to={`${previous.slug}`}
                  className="link previous"
                  alt={previous.title}
                  title={previous.title}
                >
                  ← Previous
                </Link>
              )}
              {next && (
                <Link
                  to={`${next.slug}`}
                  className="link next"
                  alt={next.title}
                  title={next.title}
                >
                  Next →
                </Link>
              )}
            </span>
          </section>
        </section>

        {postData.body.map((block, index) => {
          //check if image or text
          switch (block.slice_type) {
            case "image":
              return (
                <section className="image-slice" key={index}>
                  <figure>
                    <GatsbyImage
                      layout="constrained"
                      image={getImage(block.primary.image.gatsbyImageData)}
                      alt={
                        block.primary.image.alt
                          ? block.primary.image.alt
                          : "image"
                      }
                      title={
                        block.primary.image.alt
                          ? block.primary.image.alt
                          : "image"
                      }
                      // this is not great
                    />
                    {block.primary.caption.text && (
                      <figcaption>{block.primary.caption.text}</figcaption>
                    )}
                  </figure>
                </section>
              )

            case "text":
              return (
                <section
                  className="text-slice"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: block.primary.text.html,
                  }}
                ></section>
              )

            default:
              return null
          }
        })}

        <section className="social-share">
          <SocialShareLinks title={postData.title.text} />
        </section>
      </article>

      <section className="pagination">
        <span className="link-wrapper">
          {previous && (
            <Link
              to={`${previous.slug}`}
              className="link previous"
              alt={previous.title}
              title={previous.title}
            >
              ← Previous
            </Link>
          )}
          {next && (
            <Link
              to={`${next.slug}`}
              className="link next"
              alt={next.title}
              title={next.title}
            >
              Next →
            </Link>
          )}
        </span>
      </section>
    </Layout>
  )
}
export default BlogPostTemplate

/* Query for all posts. Access query results with `data` in component. */
export const query = graphql`
  query ($id: String!) {
    allPrismicPosts(filter: { id: { eq: $id } }) {
      nodes {
        uid
        id
        data {
          author {
            text
          }
          title {
            text
          }
          subtitle {
            text
          }
          body {
            ... on PrismicPostsDataBodyImage {
              id
              slice_type
              primary {
                caption {
                  text
                }
                image {
                  alt
                  gatsbyImageData
                }
              }
            }
            ... on PrismicPostsDataBodyText {
              id
              slice_type
              primary {
                text {
                  html
                  text
                }
              }
            }
          }
          date(formatString: "MMMM D, YYYY")
          main_image {
            alt
            gatsbyImageData
          }
        }
        tags
      }
    }
  }
`
