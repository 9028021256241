import React, { useState, useEffect } from "react"
import { StoreContext } from "../context/store-context"
import Facebook from "@images/svg/facebook.svg"
import LinkedIn from "@images/svg/linkedin.svg"
import Pinterest from "@images/svg/pinterest.svg"
import Twitter from "@images/svg/twitter.svg"
import Reddit from "@images/svg/reddit.svg"
import Tumblr from "@images/svg/tumblr.svg"
import LinkIcon from "@images/svg/link.svg"
import {
  linksWrapper,
  socialIcon,
  linkIcon,
} from "./SocialShareLinks.module.css"
import { useToggle } from "../hooks/useToggle"

export default function SocialShareLinks({
  title = "A Very Good Plan",
  img = null,
}) {
  const { currentPageUrl } = React.useContext(StoreContext)

  const {
    isToggled: isCopied,
    toggle: toggleCopied,
    setToggle: setCopiedToggle,
  } = useToggle(false)

  let timeout
  useEffect(() => {
    if (isCopied) {
      timeout = setTimeout(() => {
        setCopiedToggle(false)
      }, 3000)
    }
    return () => clearTimeout(timeout)
  }, [isCopied, timeout])

  const platforms = [
    {
      name: "Facebook",
      urlBase: "https://www.facebook.com/sharer.php?",
      color: "#1877f2",
      params: [{ u: currentPageUrl }],
      logo: (
        <Facebook
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#1877f2" }}
        />
      ),
    },
    {
      name: "Twitter",
      urlBase: "https://twitter.com/intent/tweet?",
      color: "#1da1f2",
      params: [
        { url: currentPageUrl },
        { text: title },
        {
          hashtags:
            "averygoodplan #planner #dailyplanner #journal #thoughtjournal",
        },
      ],
      logo: (
        <Twitter
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#1da1f2" }}
        />
      ),
    },
    {
      name: "Pinterest",
      urlBase: "https://pinterest.com/pin/create/bookmarklet/?",
      color: "#bd081c",
      params: [{ url: currentPageUrl }, { description: title }, { media: img }],
      logo: (
        <Pinterest
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#bd081c" }}
        />
      ),
    },
    {
      name: "LinkedIn",
      urlBase: "https://www.linkedin.com/shareArticle?",
      color: "#0a66c2",
      params: [{ url: currentPageUrl }, { title: title }],
      logo: (
        <LinkedIn
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#0a66c2" }}
        />
      ),
    },
    {
      name: "Reddit",
      urlBase: "https://reddit.com/submit?",
      color: "#ff4500",
      params: [{ url: currentPageUrl }, { title: title }],
      logo: (
        <Reddit
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#ff4500" }}
        />
      ),
    },
    {
      name: "Tumblr",
      urlBase: "https://www.tumblr.com/share/link?",
      color: "#2c4762",
      params: [{ url: currentPageUrl }, { name: title }],
      logo: (
        <Tumblr
          className={`social-icon ${socialIcon}`}
          style={{ fill: "#2c4762" }}
        />
      ),
    },
  ]

  const copyToClipboard = (copyText) => {
    navigator.clipboard.writeText(copyText)
  }

  return (
    <>
      {currentPageUrl && (
        <div className={linksWrapper}>
          {platforms.map((platform, idx) => {
            const { name, urlBase, params, logo } = platform
            let url = urlBase
            let count = 0
            params.forEach((el) => {
              for (const [key, value] of Object.entries(el)) {
                if (count != 0) url += "&"
                url += `${key}=${encodeURIComponent(value)}`
                count++
              }
            })

            return (
              <a
                key={idx}
                href={url}
                alt={`Share on ${name}`}
                title={`Share on ${name}`}
								target='_blank'
              >
                {logo ? logo : name}
              </a>
            )
          })}
          <LinkIcon
            className={`social-icon ${linkIcon} ${socialIcon}`}
						alt="Copy URL"
						title="Copy URL"
            onClick={() => {
              copyToClipboard(currentPageUrl)
              setCopiedToggle(true)
            }}
          />
          {isCopied && (
            <span
              style={{
                fontSize: "0.8rem",
                fontWeight: "600",
                color: "#d64d70",
								position: "relative",
								top: "2px",
              }}
            >
              URL copied!
            </span>
          )}
        </div>
      )}
    </>
  )
}
